<template>
  <v-layout justify-center sm12>
    <v-dialog
      v-model="dialogOpen"
      max-width="400px"
      data-cy="flight_settings-dialog"
    >
      <v-toolbar dense flat light color="primary">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <!-- Dialog content -->
      <v-layout row wrap fill-height>
        <v-flex xs12 fill-height>
          <v-card flat>
            <v-flex pa-3>
              <v-container fluid>
                <v-layout sm12>
                  <v-layout justify-center row wrap>
                    <v-flex xs12>
                    
                      <v-text-field
                        name="droneDirection"
                        label="Current Heading (deg)"
                        :value="Math.round(activeSentry.direction)"
                        disabled
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        name="droneDirection"
                        label="New Heading (deg)"
                        placeholder="e.g 270"
                        v-model="newHeading"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        name="droneDirection"
                        label="Rate (deg/sec)"
                        placeholder="e.g 4"
                        v-model="rate"
                      ></v-text-field>
                    </v-flex>

                    <!-- Form Actions -->
                    <v-layout class="mt-4">
                      <v-btn
                        @click="submitChangeDirection"
                        outline
                        color="primary"
                        class="ml-0"
                        :disabled="
                          isLoading ||
                            !newHeading ||
                            !isNewHeadingValid ||
                            !isRateValid
                        "
                        :loading="isLoading"
                      >
                        Submit
                      </v-btn>
                      <v-btn flat color="primary" @click="cancel">Cancel</v-btn>
                    </v-layout>
                  </v-layout>
                </v-layout>
              </v-container>
            </v-flex>
          </v-card>
        </v-flex>
      </v-layout>
    </v-dialog>
  </v-layout>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { DialogMixin } from '@/components/Mixins'
import { mapState } from 'vuex'

export default {
  name: 'RotateDroneDialog',
  components: {},
  mixins: [DialogMixin],
  props: {
    title: { type: String, default: 'Pre Flight Settings' },
    open: { type: Boolean, default: false },
    activeSentry: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    newHeading: '',
    dialogOpen: false,
    isLoading: false,
    rate: '5'
  }),
  computed: {
    ...mapState('sites', ['activeSiteId']),
    ...mapState('sentries', ['activeSentryId']),
    ...mapState('drone_mcu_units', ['drone_mcu_unit']),
    ...mapGetters('sentries', ['sentriesInSite']),
    sentries() {
      return this.sentriesInSite(this.activeSiteId)
    },
    isNewHeadingValid() {
      const float = parseFloat(this.newHeading)
      if (!Number.isInteger(float)) return false
      return float >= 0 && float <= 360 ? true : false
    },
    isRateValid() {
      return !(parseFloat(this.rate) <= 0 && parseFloat(this.rate) > 10)
    },
    sentry() {
      return { latitude: this.lat, longitude: this.lng, direction: this.dir }
    },

  },
  mounted() {
    this.setSettingsFromState()
  },
  methods: {
    ...mapActions('drone_mcu_units', {
      saveSettings: 'SAVE_DRONE_FLIGHT_SETTINGS'
    }),
    ...mapMutations('drone_mcu_units', {
      updateDroneMcu: 'SET_DRONE_MCU_UNITS'
    }),
    setSettingsFromState() {
      const { maxAltitude, tetherLength, maxFlightTime } =
        this.drone_mcu_unit?.settings || {}
      //set settings from vuex store

      if (maxAltitude) this.maxAltitude = maxAltitude
      if (tetherLength) this.tetherLength = tetherLength
      if (maxFlightTime) this.maxFlightTime = maxFlightTime
    },
    onMapCenter() {
      const { latitude, longitude } = this.site
      return this.updateCoordinates([latitude, longitude])
    },
    resetSafetyChecks() {
      this.isNoOverheadObstruction = false
      this.isEnvironmentSafe = false
      this.isSafeToFlyZone = false
    },

    submitChangeDirection() {
      this.$emit('rotate', { heading: this.newHeading, rate: this.rate })
      this.cancel()
    },
    cancel() {
      this.dialogOpen = false
    }
  },
  watch: {
    open(v) {
      this.dialogOpen = v
    },
    dialogOpen(v) {
      if (v === false) {
        this.$emit('closeDialog')
      }
    }
  }
}
</script>
<style scoped>
.v-input--selection-controls {
  padding: 0 !important;
  margin: 0 !important;
}
.v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
  margin-bottom: 0 !important;
}
</style>
