<template>
  <v-layout justify-center sm12>
    <v-dialog
      v-model="dialogOpen"
      max-width="400px"
      data-cy="flight_settings-dialog"
    >
      <v-toolbar dense flat light color="primary">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <!-- Dialog content -->
      <v-layout row wrap fill-height>
        <v-flex xs12 fill-height>
          <v-card flat>
            <v-flex pa-3>
              <v-container fluid>
                <v-layout sm12>
                  <v-layout justify-center row wrap>
                    <v-flex xs12>
                      <v-select
                        v-if="!isCustomTetherLength"
                        label="Tether Length (ft)"
                        :items="['150', '400']"
                        v-model="tetherLength"
                      />
                      <v-text-field
                        v-if="isCustomTetherLength"
                        name="customTetherLength"
                        label="Tether Length (ft)"
                        type="number"
                        v-model="tetherLength"
                      ></v-text-field>

                      <v-switch
                        color="primary"
                        v-model="isCustomTetherLength"
                        :label="`Custom Tether Length`"
                      ></v-switch>
                    </v-flex>
                    <!-- Max flight time remove till phase 2 -->
                    <!-- <v-flex xs12>
                      <v-text-field
                        name="maxFlightTime"
                        label="Max Flight Time (min)"
                        type="number"
                        v-model="maxFlightTime"
                      ></v-text-field>
                    </v-flex> -->
                    <v-flex xs12>
                      <v-text-field
                        name="maxAltitude"
                        label="Altitude(ft)"
                        type="number"
                        :disabled="!isCustomAltitude"
                        v-model="maxAltitude"
                      ></v-text-field>

                      <v-switch
                        color="primary"
                        v-model="isCustomAltitude"
                        :label="`Custom Altitude`"
                      ></v-switch>
                    </v-flex>

                    <v-layout column v-if="preTakeOff">
                      <!-- Safety Confirmation -->
                      <v-layout>
                        <v-flex class="subheading text--primary my-3" sm12>
                          Safety Confirmation
                        </v-flex>
                      </v-layout>

                      <v-layout column>
                        <v-checkbox
                          color="primary"
                          v-model="isNoOverheadObstruction"
                          :label="`Confirm No overhead Obstruction`"
                        ></v-checkbox>
                        <v-checkbox
                          color="primary"
                          v-model="isEnvironmentSafe"
                          :label="`Confirm Environment Safe`"
                        ></v-checkbox>

                        <v-checkbox
                          color="primary"
                          v-model="isSafeToFlyZone"
                          :label="`Confirm Safe to fly zone`"
                        ></v-checkbox>
                      </v-layout>
                    </v-layout>

                    <!-- Form Actions -->
                    <v-layout class="mt-4">
                      <v-btn
                        @click="submitFlightControls"
                        outline
                        color="primary"
                        class="ml-0"
                        :disabled="!isSafeToTakeoff || isLoading"
                        :loading="isLoading"
                      >
                        {{ preTakeOff ? 'Confirm & Takeoff' : 'Save' }}
                      </v-btn>
                      <v-btn flat color="primary" @click="cancel">Cancel</v-btn>
                    </v-layout>
                    <span style="color:red">{{ error }}</span>
                  </v-layout>
                </v-layout>
                <!-- Safety Confirmation End -->

                <!-- Take Off Button -->
              </v-container>
            </v-flex>
          </v-card>
        </v-flex>
      </v-layout>
    </v-dialog>
  </v-layout>
</template>
<script>
import { mapActions, mapMutations } from 'vuex'
import { DialogMixin } from '@/components/Mixins'
import { mapState } from 'vuex'
export default {
  name: 'FlightSettings',
  components: {},
  mixins: [DialogMixin],
  props: {
    title: { type: String, default: 'Pre Flight Settings' },
    open: { type: Boolean, default: false },
    preTakeOff: { type: Boolean, default: false } //we show safety confirmations if its pre-take-off
  },
  data: () => ({
    dialogOpen: false,
    isLoading: false,
    flightNumber: 9,
    isNoOverheadObstruction: false,
    isEnvironmentSafe: false,
    isSafeToFlyZone: false,
    isCustomAltitude: false,
    isCustomTetherLength: true,
    maxFlightTime: 8,
    maxAltitude: ['150'],

    speed: '',
    valid: false,
    tetherLength: '150',
    dialog: false,
    metric: true,
    imperialUnit: false,
    error: null
  }),
  computed: {
    ...mapState('drone_mcu_units', ['drone_mcu_unit']),
    tetherLengthState() {
      return this.drone_mcu_unit?.settings?.tetherLength
    },
    isSafeToTakeoff() {
      //only disabled if pre take off prop is true
      return !(
        this.preTakeOff &&
        !(
          this.isNoOverheadObstruction &&
          this.isEnvironmentSafe &&
          this.isSafeToFlyZone
        )
      )
    }
  },
  mounted() {
    this.setSettingsFromState()
  },
  methods: {
    ...mapActions('drone_mcu_units', {
      saveSettings: 'SAVE_DRONE_FLIGHT_SETTINGS'
    }),
    ...mapMutations('drone_mcu_units', {
      updateDroneMcu: 'SET_DRONE_MCU_UNITS'
    }),
    setSettingsFromState() {
      const { maxAltitude, tetherLength, maxFlightTime } =
        this.drone_mcu_unit?.settings || {}
      //set settings from vuex store

      if (maxAltitude) this.maxAltitude = maxAltitude
      if (tetherLength) this.tetherLength = tetherLength
      if (maxFlightTime) this.maxFlightTime = maxFlightTime
    },
    resetSafetyChecks() {
      this.isNoOverheadObstruction = false
      this.isEnvironmentSafe = false
      this.isSafeToFlyZone = false
    },
    async submitFlightControls() {
      //reset any errors
      this.error = null
      try {
        //make sure drone has an IP, if not throw error
        if (!this.drone_mcu_unit.ip) {
          throw new Error('IP not found on drone_mcu_unit')
        }
        //set loading state
        this.isLoading = true

        const settings = {
          maxAltitude: this.maxAltitude,
          maxFlightTime: this.maxFlightTime,
          tetherLength: this.tetherLength
        }
        //save the settings
        await this.saveSettings({ ip: this.drone_mcu_unit.ip, body: settings })
        this.updateDroneMcu({ settings })
        //if pretake off, we emit takeoff command
        if (this.preTakeOff) {
          this.$emit('takeoff')
        }
        this.isLoading = false
        this.cancel()
      } catch (err) {
        console.error(err)
        this.error = 'Drone not connected'
        this.isLoading = false
      }
    },
    cancel() {
      this.dialogOpen = false
      this.resetSafetyChecks()
    }
  },
  watch: {
    tetherLengthState(length) {
      if (length) {
        this.setSettingsFromState()
      }
    },
    open(v) {
      this.dialogOpen = v
    },
    dialogOpen(v) {
      if (v === false) {
        this.$emit('closeDialog')
      }
    },
    tetherLength(length) {
      if (length <= 150) return (this.maxAltitude = length - 10)
      return (this.maxAltitude = length - 30)
    }
  }
}
</script>
<style scoped>
.v-input--selection-controls {
  padding: 0 !important;
  margin: 0 !important;
}
.v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
  margin-bottom: 0 !important;
}
</style>
