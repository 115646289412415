



































































































































































































































































import Vue from 'vue'
import {mapActions, mapGetters, mapState} from 'vuex'
import FlightSettingsDialog from '@/components/Dialogs/FlightSettingsDialog.vue'
import RotateDroneDialog from '@/components/Dialogs/RotateDroneDialog.vue'
import LandingConfirmationDialog
  from '@/components/Widgets/FlightControl/Components/Widgets/LandingConfirmationDialog.vue'
import ElevationControlDialog from '@/components/Widgets/FlightControl/Components/Widgets/ElevationControlDialog.vue'

import {
  EZATFlightCommands,
  EZATFlightState,
  EDroneCommandStatus,
  IDroneMCUUnitsState, IDroneMCUUnit, ICommand
} from "@/store/modules/drone_mcu_units/types"
import dmuIndex from "@/store/modules/drone_mcu_units";

const props = {
  activeSentry: {
    type: Object,
    default: () => ({})
  },
  activeCameraId: {
    default: 0
  },
}

// Add flight routines

interface IFlightRoutine {
  id: number
  name: string
  commandList: string[]
}

enum ESendToDirection {
  RotateTo0Degrees = 'rotate0',
  RotateTo45Degrees = 'rotate45',
  RotateTo90Degrees = 'rotate90',
  RotateTo135Degrees = 'rotate135',
  RotateTo180Degrees = 'rotate180',
  RotateTo225Degrees = 'rotate225',
  RotateTo270Degrees = 'rotate270',
  RotateTo315Degrees = 'rotate315',
  RotateTo360Degrees = 'rotate360'
}

// Set up flight routines
// commandList will replay as flight routines are ran
const FlightRoutines: IFlightRoutine[] = [
  {
    id: 1,
    name: 'Scan -45 degrees to +45 degrees',
    commandList: [
      ESendToDirection.RotateTo315Degrees, // start and end here
      ESendToDirection.RotateTo360Degrees,
      ESendToDirection.RotateTo45Degrees,
      ESendToDirection.RotateTo360Degrees
    ]
  },
  {
    id: 2,
    name: 'Scan -90 degrees to +90 degrees',
    commandList: [
      ESendToDirection.RotateTo270Degrees, // start and end here
      ESendToDirection.RotateTo315Degrees,
      ESendToDirection.RotateTo360Degrees,
      ESendToDirection.RotateTo45Degrees,
      ESendToDirection.RotateTo90Degrees,
      ESendToDirection.RotateTo45Degrees,
      ESendToDirection.RotateTo360Degrees,
      ESendToDirection.RotateTo315Degrees
    ]
  },
  {
    id: 3,
    name: 'Clockwise Every 90 then back',
    commandList: [
      ESendToDirection.RotateTo0Degrees, // start and end here
      ESendToDirection.RotateTo90Degrees,
      ESendToDirection.RotateTo180Degrees,
      ESendToDirection.RotateTo270Degrees,
      ESendToDirection.RotateTo360Degrees,
      ESendToDirection.RotateTo270Degrees,
      ESendToDirection.RotateTo180Degrees,
      ESendToDirection.RotateTo90Degrees
    ]
  },

  {
    id: 4,
    name: 'Anti-Clockwise Every 90 then back',
    commandList: [
      ESendToDirection.RotateTo360Degrees, // start and end here
      ESendToDirection.RotateTo270Degrees,
      ESendToDirection.RotateTo180Degrees,
      ESendToDirection.RotateTo90Degrees,
      ESendToDirection.RotateTo0Degrees,
      ESendToDirection.RotateTo90Degrees,
      ESendToDirection.RotateTo180Degrees,
      ESendToDirection.RotateTo270Degrees
    ]
  },

  {
    id: 5,
    name: 'Front and back',
    commandList: [
      ESendToDirection.RotateTo0Degrees,
      ESendToDirection.RotateTo180Degrees
    ]
  }
]

export default Vue.extend({
  name: 'DroneFlightControl',
  props,
  components: {
    FlightSettingsDialog,
    RotateDroneDialog,
    LandingConfirmationDialog,
    ElevationControlDialog
  },
  data: (): any => {
    return {
      rotateDroneDialogOpen: false,
      flightSettingsDialogOpen: false,
      flightRoutinesDialogOpen: false,
      landingConfirmationDialogOpen: false,
      elevationDroneDialogOpen: false,
      targetElevation: 0,
      landingMode: null,
      imperialUnit: true,
      commandQueue: [],
      preFlight: false,
      command_status_data: {
        status: EDroneCommandStatus.IDLE,
        color: 'grey'
      },

      //flight routine data
      flightRoutines: FlightRoutines,
      repeatCount: 1,
      firstCommand: true,
      flightRoutineSpeed: 1,
      delayBetweenCommands: 30,
      activeFlightRoutine: null,
      selectedFlightRoutine: null,
      selectedFlightRoutineCommands: null,
      isFlightRoutineRunning: false
    }
  },
  computed: {
    ...mapGetters('sentries', ['activeSentryId']),
    ...mapState('drone_mcu_units', {
      drone_mcu_unit: (state: IDroneMCUUnitsState): IDroneMCUUnit => state.drone_mcu_unit,
      commands: (state: IDroneMCUUnitsState): ICommand => state.commands
    }),
    ...mapState('sensors', ['sentry']),
    flightControlBtnText() {
      if (this.activeCameraId) return 'Hide Camera View'
      return 'Camera View'
    },
    commandStatus(): string {
      const status = this.getCommandState()?.last_command?.status
      const msg = this.getCommandState()?.last_command?.status_message

      if (status === EDroneCommandStatus.FAILED) {
        return this.getCommandState()?.last_command?.status + ': ' + msg
      }
      return this.getCommandState()?.last_command?.status
    },
    lastCommand() {
      return this.getCommandState()?.last_command?.command
    },
    lastCommandObj() {
      return this.getCommandState()?.last_command
    },
    lastCommandStatus() {
      return this.getCommandState()?.last_command?.status
    },
    commandInProgress() {
      return this.getCommandState()?.last_command?.status === 'queued'
    },
    isDroneConnected() {
      if (this.drone_mcu_unit?.state === undefined) {
        return false
      }
      return true
    },
    isDroneInAir() {
      if (this.drone_mcu_unit?.state === EZATFlightState.InAir) {
        return true
      }
      return false
    },

    isDroneMoving() {
      if (this.drone_mcu_unit?.state === EZATFlightState.Moving) {
        return true
      }
      return false
    },
    isDroneOnGround() {
      if (this.drone_mcu_unit?.state === EZATFlightState.OnGround) {
        return true
      }
      return false
    },
    showTakeOffIcon() {
      if (this.drone_mcu_unit?.state === EZATFlightState.OnGround) {
        return true
      }
      return false
    },
    commandStatusColor(): object {
      return {
        red: this.getCommandStatus().color === 'red',
        orange: this.getCommandStatus().color === 'orange',
        green: this.getCommandStatus().color === 'green'
      }
    },
    currentSentryHeading(): any {
      return this.activeSentry.direction
    },
    flightRoutineCommandList(): any {
      const routine = FlightRoutines.find(
        (r: any) => r.id === this.selectedFlightRoutine
      )
      return routine
    }
  },
  methods: {
    ...mapActions('drone_mcu_units', {
      sendCommand: 'SEND_DRONE_MCU_UNITS_COMMAND'
    }),
    cameraToggle() {
      this.$emitter.emit('openPopup', this.activeSentry.id)
      this.$emit('cameraToggled')
    },
    feetToMeters(feet: number) {
      return feet / 3.281
    },

    handleClickFlightRoutines() {
      //toggle dialog open if not running
      if (!this.isFlightRoutineRunning) {
        return this.toggleFlightRoutinesOpen()
      }
      //reset values if flight routine running
      this.resetFlightRoutineValues()
      //if routine running, stop the flight routine
      return (this.isFlightRoutineRunning = false)
    },

    toggleFlightRoutinesOpen() {
      this.flightRoutinesDialogOpen = !this.flightRoutinesDialogOpen
    },

    toggleFlightSettingsOpen(event): any {
      if (event?.target?.innerHTML === 'Flight Config') {
        this.preFlight = false
        return (this.flightSettingsDialogOpen = !this.flightSettingsDialogOpen)
      }
      if (event?.target && event.target.attributes[1].value === 'takeoff') {
        if (this.commandInProgress || this.isDroneInAir) return
        this.preFlight = true
        return (this.flightSettingsDialogOpen = !this.flightSettingsDialogOpen)
      }
      this.preFlight = false
      this.flightSettingsDialogOpen = !this.flightSettingsDialogOpen
    },

    toggleLandingConfirmationOpen(): any {
      if (this.commandInProgress || !this.isDroneInAir) return
      this.landingConfirmationDialogOpen = !this.landingConfirmationDialogOpen
    },
    resetFlightRoutineValues(): void {
      //reset vars
      this.selectedFlightRoutineCommands = null
      this.selectedFlightRoutine = null
      this.repeatCount = 0
      this.commandQueue = []
      this.flightRoutineSpeed = 1
    },

    handleUseThisFlightRoutine(): void {
      this.firstCommand = true

      //add the selectedCommand list to the command queue
      this.addSelectedFlightRoutineCommandsToQueue()

      //set the flight routine running flag to true
      this.isFlightRoutineRunning = true

      //toggle dialog modal
      this.toggleFlightRoutinesOpen()
    },
    handleRoutineSelectChange(): void {
      //clear command queue
      this.commandQueue = []
    },
    openRotateDialog() {
      if (!this.isDroneInAir) return
      this.rotateDroneDialogOpen = true
    },
    closeRotateDialog() {
      this.rotateDroneDialogOpen = false
    },

    toggleElevationDialog() {
      if (!this.isDroneInAir) return
      this.elevationDroneDialogOpen = !this.elevationDroneDialogOpen
    },

    closeLandingConfirmationDialog() {
      this.landingConfirmationDialogOpen = false
    },
    takeoffConfirmedFromDialog() {
      this.triggerTakeOff()
    },
    landingConfirmedFromDialog() {
      this.landingConfirmationDialogOpen = !this.landingConfirmationDialogOpen
      this.triggerLanding()
    },
    async rotateSubmitFromDialog(obj: any) {
      const {heading, rate} = obj
      this.rotateDroneDialogOpen = false
      await this.sendChangeDirCommand(heading.toString(), rate.toString())
    },

    async elevationSubmitFromDialog(obj: any) {
      const {targetElevation} = obj
      this.targetElevation = targetElevation
      await this.sendAltitudeCommand(this.targetElevation)
    },
    triggerLanding() {
      if (!this.isDroneInAir)
        return console.warn('Cannot land as drone is not in air')
      if (this.landingMode === EZATFlightCommands.ReturnToLaunch) {
        this.commandQueue = [EZATFlightCommands.ReturnToLaunch]
      } else if (this.landingMode === EZATFlightCommands.Land) {
        this.commandQueue = [EZATFlightCommands.Land]
      }
    },
    async sendLandCommand() {
      return this.sendCommand({
        device_id: this.drone_mcu_unit.id,
        command: EZATFlightCommands.Land,
        parameters: [
          {
            key: 'Type',
            value: 'Drone'
          }
        ]
      })
    },
    async sendAltitudeCommand(targetAltitude: number) {
      return this.sendCommand({
        device_id: this.drone_mcu_unit.id,
        command: EZATFlightCommands.AltitudeControl,
        parameters: [
          {
            key: 'Type',
            value: 'Drone'
          },
          {
            key: 'alt',
            value: this.feetToMeters(targetAltitude).toFixed(1)
          }
        ]
      })
    },
    async sendReturnToLaunchCommand() {
      return this.sendCommand({
        device_id: this.drone_mcu_unit.id,
        command: EZATFlightCommands.ReturnToLaunch,
        parameters: [
          {
            key: 'Type',
            value: 'Drone'
          }
        ]
      })
    },
    async sendWaitCommand(delay: number): Promise<void> {
      this.lastCommandObj = {id: `wait_${delay}`, status: 'queued'}
      setTimeout(() => (this.lastCommandObj.status = 'successful'), delay * 100)
    },

    async sendPreflightSettingsCommand(): Promise<any> {
      const {tetherLength, maxFlightTime} =
      this.drone_mcu_unit?.settings || {}
      return this.sendCommand({
        device_id: this.drone_mcu_unit.id,
        command: 'preflight',
        parameters: [
          {
            key: 'Type',
            value: 'Drone'
          },
          {
            key: 'time_limit',
            value: maxFlightTime.toString() //to fixed because backend expects float
          },
          {
            key: 'tether',
            value: tetherLength
          }
        ]
      })
    },
    async sendTakeoffCommand(): Promise<any> {
      const {maxAltitude} = this.drone_mcu_unit?.settings || {}
      return this.sendCommand({
        device_id: this.drone_mcu_unit.id,
        command: 'takeoff',
        parameters: [
          {
            key: 'Type',
            value: 'Drone'
          },
          {
            key: 'alt',
            value: this.feetToMeters(maxAltitude).toFixed(1) //in meters
          }
        ]
      })
    },
    async sendArmCommand(): Promise<any> {
      return this.sendCommand({
        device_id: this.drone_mcu_unit.id,
        command: 'arm',
        parameters: [
          {
            key: 'Type',
            value: 'Drone'
          }
        ]
      })
    },
    async triggerTakeOff(): Promise<void> {
      this.commandQueue = ['preflight', 'arm', 'takeoff']
    },
    getCommandState(): object {
      return this.commands //store
    },
    getLocalCommandStatus(): any {
      return this.command_status_data.status
    },
    //Command Loading state
    getCommandStatus(): { status: string; color: string } {
      //get command status from state
      let status = this.getCommandState()?.last_command?.status

      let color = ''
      //set color and status depending on state
      switch (status) {
        case EDroneCommandStatus.SUCCESS:
          status = 'Successful'
          color = 'green'
          break
        case EDroneCommandStatus.FAILED:
          status = 'Failed'
          color = 'red'
          break
        case EDroneCommandStatus.QUEUED:
          status = 'Queued'
          color = 'orange'
          break
        case EDroneCommandStatus.IDLE:
          status = 'Idle'
          break
        default:
          status = ''
          break
      }

      return {status, color}
    },
    setCommandStatusValues({
                             status,
                             color
                           }: {
      status: string
      color: string
    }): void {
      this.command_status_data = {
        status,
        color
      }
    },

    // convert strings to commands
    async processcommandQueue(commandQueue: string[]) {
      const speed = this.flightRoutineSpeed.toString()
      if (!commandQueue?.[0]) return
      //take first command and process it
      switch (commandQueue[0]) {
        case 'preflight':
          await this.sendPreflightSettingsCommand()
          break
        case 'arm':
          await this.sendArmCommand()
          break
        case 'takeoff':
          await this.sendTakeoffCommand()
          break
        case 'land':
          await this.sendLandCommand()
          break
        case 'rtl':
          await this.sendReturnToLaunchCommand()
          break
        case 'altitude':
          await this.sendAltitudeCommand(this.targetElevation)
          break
        case ESendToDirection.RotateTo0Degrees:
          await this.sendChangeDirCommand('0', speed)
          break
        case ESendToDirection.RotateTo45Degrees:
          await this.sendChangeDirCommand('45', speed)
          break
        case ESendToDirection.RotateTo90Degrees:
          await this.sendChangeDirCommand('90', speed)
          break
        case ESendToDirection.RotateTo135Degrees:
          await this.sendChangeDirCommand('135', speed)
          break
        case ESendToDirection.RotateTo180Degrees:
          await this.sendChangeDirCommand('180', speed)
          break
        case ESendToDirection.RotateTo225Degrees:
          await this.sendChangeDirCommand('225', speed)
          break
        case ESendToDirection.RotateTo270Degrees:
          await this.sendChangeDirCommand('270', speed)
          break
        case ESendToDirection.RotateTo315Degrees:
          await this.sendChangeDirCommand('315', speed)
          break
        case ESendToDirection.RotateTo360Degrees:
          await this.sendChangeDirCommand('360', speed)
          break
      }
    },
    // add the commands to the queue
    addSelectedFlightRoutineCommandsToQueue(): void {
      //loop over repeat count
      for (let i = 0; i < this.repeatCount; i++) {
        this.commandQueue.push(...this.selectedFlightRoutineCommands)
        //add the selected commands to the selectedFlightRoutineCommands
      }
    },

    async sendChangeDirCommand(degrees: string, rate: string): Promise<any> {
      return this.sendCommand({
        device_id: this.drone_mcu_unit.id,
        command: 'heading',
        parameters: [
          {
            key: 'Type',
            value: 'Drone'
          },
          {
            key: 'degrees',
            value: degrees
          },
          {
            key: 'rate',
            value: rate
          }
        ]
      })
    },

    async dequeueCommand(cmd: any, oldCmd: any): Promise<void> {
      //pull the delay for each of the commands
      const delayBetweenCommands = this.delayBetweenCommands
        ? this.delayBetweenCommands
        : 30000

      // guards
      if (!cmd) return
      if (!oldCmd) return
      if (cmd.id !== oldCmd.id) return

      // return if the response isn't successful
      if (cmd.status !== EDroneCommandStatus.SUCCESS) return
      if (oldCmd.status !== EDroneCommandStatus.QUEUED) return

      //check if command is a heading command
      if (cmd.command === 'heading') {
        while (this.isDroneMoving) {
          await new Promise(r => {
            setTimeout(r, 1000)
          })
        }

        //send first command without a delay
        if (this.firstCommand) {
          await new Promise(r => {
            setTimeout(r, 5000)
          })
        }

        //every following command we send with time between (this is to make sre we give enough time for the drone state to change before we evaluate). Very hacky, needs to be refactored in the future
        if (!this.firstCommand) {
          await new Promise(r => {
            setTimeout(r, 2000)
          })

          //another check for is drone is moving
          while (this.isDroneMoving) {
            await new Promise(r => {
              setTimeout(r, 1000)
            })
          }

          if (this.isDroneInAir) {
            await new Promise(r => {
              //Add wait delay before firing the next command
              setTimeout(r, delayBetweenCommands * 1000)
            })

            return this.commandQueue.shift()
          }
        }
      }

      // next command
      this.commandQueue.shift()

      // first command complete
      this.firstCommand = false
    },

    cancelFlightRoutinesDialog(): void {
      this.selectedFlightRoutine = null
      this.resetFlightRoutineValues()
      this.toggleFlightRoutinesOpen()
    },

    setLandingMode(payload: EZATFlightCommands): void {
      this.landingMode = payload
    }
  },
  watch: {
    command_status(status: EDroneCommandStatus) {
      //get the color
      const {color} = this.getCommandStatus()
      // set the values
      this.setCommandStatusValues({status, color})
    },

    selectedFlightRoutine(routineCode) {
      //wipe the command queue
      this.commandQueue = []

      //find related routine
      const flightRoutineObject: any = FlightRoutines.find(
        (r: IFlightRoutine) => r.id === routineCode
      )

      this.activeFlightRoutine = flightRoutineObject.name
      //add flight routine command list to selectedFlightRoutineCommands
      this.selectedFlightRoutineCommands = flightRoutineObject.commandList
    },
    commandQueue(commandQueue) {
      if (commandQueue.length === 0) {
        this.isFlightRoutineRunning = false
      }
      //log commands list
      // process the command queue
      this.processcommandQueue(commandQueue)
    },
    lastCommandObj: {
      //for dequeuing items
      handler: function (cmd, oldCmd) {
        if (!this.commandQueue?.[0]) {
          return (this.isFlightRoutineRunning = false)
        }
        //exit if queue is empty
        this.dequeueCommand(cmd, oldCmd)
      },
      deep: true
    }
  }
})
