









export default {
  name: 'TelemetryItem',
  props: ['heading', 'value', 'showDeg']
}
