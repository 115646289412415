














































































































































import Vue, { VueConstructor } from 'vue';
import { mapState } from 'vuex'
import TelemetryItem from './components/TelemetryItem.vue'
import FlightSettingsDialog from '../../Dialogs/FlightSettingsDialog.vue'
import {
  EDroneCommandStatus,
  ICommand,
  EZATGPSStatuses,
  EZATFlightMode,
  flightStateMapping,
  EZATFlightErrors,
  IDroneMCUUnitsState,
  IDroneMCUUnit,
  IData,
  ITelemetryData
} from '@/store/modules/drone_mcu_units/types'
import TypedEmitter from "typed-emitter"
// import FlightRoutinesDialog from '../../Dialogs/FlightRoutinesDialog.vue'

const props = {
  sentry: {
    type: Object
  },
  activeCameraId: {
    default: 0
  }
}

type MessageEvents = {
  openPopup: (id: number) => void
}

interface VuexBindings {
  drone_mcu_unit: IDroneMCUUnit
  commands: ICommand,
  $emitter: TypedEmitter<MessageEvents>
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  name: 'TelemetryPanel',
  components: { TelemetryItem, FlightSettingsDialog },
  props,
  data: (): IData => {
    return {
      imperialUnit: true,
      flightSettingsDialogOpen: false,
      command_status_data: {
        status: EDroneCommandStatus.IDLE,
        color: 'grey'
      }
    }
  },
  computed: {
    ...mapState('drone_mcu_units', {
      drone_mcu_unit: (state: IDroneMCUUnitsState) => state.drone_mcu_unit,
      commands: (state: IDroneMCUUnitsState) => state.commands
    }),
    flightControlBtnText(): string {
      if (this.activeCameraId) return 'Hide Flight Controls'
      return 'Show Flight Controls'
    },
    command_status(): string {
      return this.commands?.status
    },
    commandStatusColor(): object {
      return {
        red: this.getCommandStatus().color === 'red',
        orange: this.getCommandStatus().color === 'orange',
        green: this.getCommandStatus().color === 'green'
      }
    },
    telemetry(): ITelemetryData {
      return {
        flight_mode: this.drone_mcu_unit?.flight_mode || 'Offline',
        gps_sats: this.drone_mcu_unit?.gps_sats || 0,
        heading: 0, //get from sentry
        gps_status: this.drone_mcu_unit?.gps_status || 'Disconnected',
        flight_time: 2000 ? 2000 : 0,
        wattage: this.drone_mcu_unit?.wattage || 0,
        voltage: this.drone_mcu_unit?.voltage || 0,
        altitude: this.drone_mcu_unit?.altitude || 0,
        status: this.drone_mcu_unit?.status || 'Offline',
        errors: [
          EZATFlightErrors.CableLimit,
          EZATFlightErrors.MotorFail,
          EZATFlightErrors.LoadCellFail
        ]
      }
    },
    hasFailures(): boolean {
      if (!this.failures) {
        return false
      }
      return this.failures.length > 0
    },
    failures(): Array<string> {
      return this.drone_mcu_unit.failures
    }
  },
  methods: {
    cameraToggle(): void {
      this.$emitter.emit('openPopup', this.sentry.id)
      this.$emit('cameraToggled')
    },
    toggleFlightSettingsOpen(): void {
      this.flightSettingsDialogOpen = !this.flightSettingsDialogOpen
    },
    flightState(): string {
      return flightStateMapping[
        this.drone_mcu_unit?.state || flightStateMapping.on_ground
      ]
    },
    flightMode(flight_mode: EZATFlightMode): string {
      switch (flight_mode) {
        case EZATFlightMode.Takeoff:
          return 'Take Off'
        case EZATFlightMode.Hold:
          return 'Hold'
        case EZATFlightMode.Mission:
          return 'Mission'
        case EZATFlightMode.Rattitude:
          return 'Rattitude'
        case EZATFlightMode.Acro:
          return 'Acro'
        case EZATFlightMode.Land:
          return 'Land'
        case EZATFlightMode.FollowMe:
          return 'Follow me'
        case EZATFlightMode.Manual:
          return 'Manual'
        case EZATFlightMode.ReturnToLaunch:
          return 'Return to Launch'
        case EZATFlightMode.Stabilized:
          return 'Stabilized'
        case EZATFlightMode.Ready:
          return 'Ready'
        default:
          return 'Unknown'
      }
    },
    getGPSStatus(gps_status: EZATGPSStatuses): string {
      switch (gps_status) {
        case EZATGPSStatuses.CONNECTED:
          return 'Connected'
        case EZATGPSStatuses.DISCONNECTED:
          return 'Connected'
      }
    },
    //COMMAND_STATUS SECTION ->>>>>>>>>>>>. TODO TO BE MOVED TO THE FLIGHT CONTROL SECTION
    getLocalCommandStatus(): any {
      return this.command_status_data.status
    },
    //Command Loading state
    getCommandStatus(): { status: string; color: string } {
      //get command status from state
      let status = this.commands?.last_command?.status

      //init color
      let color = ''
      //set color and status depending on state
      switch (status) {
        case EDroneCommandStatus.SUCCESS:
          status = 'Successful'
          color = 'green'
          break
        case EDroneCommandStatus.FAILED:
          status = 'Failed'
          color = 'red'
          break
        case EDroneCommandStatus.QUEUED:
          status = 'Queued'
          color = 'orange'
          break
        case EDroneCommandStatus.IDLE:
          status = 'Idle'
          break
        default:
          status = ''
          break
      }
      return { status, color }
    },
    setCommandStatusValues({
      status,
      color
    }: {
      status: EDroneCommandStatus
      color: string
    }): void {
      this.command_status_data = {
        status,
        color
      }
    }
  },
  watch: {
    command_status(status: EDroneCommandStatus): void {
      //get the color
      const { color } = this.getCommandStatus()
      // set the values
      this.setCommandStatusValues({ status, color })
    }
  }
})
