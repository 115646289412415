
































































































import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { DialogMixin } from '@/components/Mixins'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'ElevationControlDialog',
  components: {},
  mixins: [DialogMixin],
  props: {
    title: { type: String, default: 'Elevation Control Settings' },
    open: { type: Boolean, default: false },
    activeSentry: {
      type: Object,
      default: () => ({})
    }
  },
  data: (): any => ({
    dialogOpen: false,
    isLoading: false,
    elevationDelta: 0,
    elevationErrors: [],
    maxElevation: 0,
    targetElevation: 0,
    tetherLength: 0
  }),
  computed: {
    ...mapState('sites', ['activeSiteId']),
    ...mapState('sentries', ['activeSentryId']),
    ...mapState('drone_mcu_units', ['drone_mcu_unit']),
    ...mapGetters('sentries', ['sentriesInSite']),
    sentries(): any {
      return this.sentriesInSite(this.activeSiteId)
    },
    tooHighError(): boolean {
      return this.targetElevation > this.maxElevation
    },
    drone_mcu_settings() {
      return this.drone_mcu_unit.settings
    }
  },
  mounted(): void {
    this.setSettingsFromState()
  },
  created(): void {
    this.targetElevation = this.activeSentry.altitude
  },
  methods: {
    ...mapActions('drone_mcu_units', {
      saveSettings: 'SAVE_DRONE_FLIGHT_SETTINGS'
    }),
    ...mapMutations('drone_mcu_units', {
      updateDroneMcu: 'SET_DRONE_MCU_UNITS'
    }),
    setSettingsFromState(): any {
      const { tetherLength } = this.drone_mcu_unit?.settings || {}
      //set settings from vuex store
      if (tetherLength) this.maxElevation = parseInt(tetherLength)
    },
    submitChangeElevation(): void {
      this.elevationDelta = 0
      this.$emit('elevation', {
        targetElevation: this.targetElevation
      })
      this.cancel()
    },
    metersToFeet(meters: number): number {
      return Math.round(meters * 3.281)
    },

    cancel(): void {
      this.dialogOpen = false
    }
  },
  watch: {
    open(v): void {
      this.dialogOpen = v
      this.setSettingsFromState()
    },
    dialogOpen(v): void {
      if (v === false) {
        this.$emit('closeDialog')
      }
    },
    elevationDelta(): void {
      this.targetElevation = Math.round(
        this.metersToFeet(this.activeSentry.altitude) + this.elevationDelta
      )
    }
  }
})
