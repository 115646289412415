


















































































import Vue from 'vue'
import { mapActions, mapMutations } from 'vuex'
import { DialogMixin } from '@/components/Mixins'
import { mapState } from 'vuex' 

export default Vue.extend({
  name: 'LandingConfirmationDialog',
  components: {},
  mixins: [DialogMixin],
  props: {
    title: { type: String, default: 'Landing confirmation' },
    open: { type: Boolean, default: false }
  },
  data: () => ({
    dialogOpen: false,
    isLoading: false,
    isSafeToLand: false,
    landingMode: null
  }),
  computed: {
    ...mapState('drone_mcu_units', ['drone_mcu_unit'])
  },
  methods: {
    ...mapActions('drone_mcu_units', {
      saveSettings: 'SAVE_DRONE_FLIGHT_SETTINGS'
    }),
    ...mapMutations('drone_mcu_units', {
      updateDroneMcu: 'SET_DRONE_MCU_UNITS'
    }),
    handleConfirmLanding() {
      this.$emit('landing')
      this.isSafeToLand = false
    },
    cancel(): void {
      this.dialogOpen = false
    }
  },
  watch: {
    open(v: boolean): void {
      this.dialogOpen = v
    },
    dialogOpen(v: boolean): void {
      if (v === false) {
        this.$emit('closeDialog')
      }
    },
    landingMode(v: string): void {
      this.$emit('landingMode', this.landingMode)
    }
  }
})
