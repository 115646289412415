<template>
  <v-card>
    <v-card-title class="pa-0">
      <v-subheader>
        {{ title }}
      </v-subheader>
      <v-spacer />
      <template v-if="isAuthorized('site_manager') && editMode">
        <v-tooltip right>
          <template #activator="{on}">
            <v-btn
              light
              icon
              flat
              data-cy="add-installation"
              color="primary"
              v-on="on"
              @click="addNewInstallation"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <span>Add a new installation to the site</span>
        </v-tooltip>
      </template>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-expansion-panel
        class="elevation-0"
        v-model="panelStates"
        expand
        @input="panelUpdate"
      >
        <v-expansion-panel-content
          v-for="(item, i) in sentries"
          :ref="`sentry-${item.site_id}-${item.id}`"
          :key="`s${i}`"
          :data-cy="`installation-${item.name}-panel`"
          :expand-icon="
            cameraPage ? null : editMode ? 'edit' : $vuetify.icons.expand
          "
        >
          <v-layout slot="header" align-center row spacer>
            <v-flex xs2>
              <v-icon :color="$_getStatusColor(item)" data-cy="installation-status">
                radio_button_checked
              </v-icon>
            </v-flex>
            <v-flex xs8>
              <span class="body-1" data-cy="installation-title">
                {{ item.name }}
              </span>
              <span>
                <template v-if="panelStates[i]">
                  <br />
                  <small>
                    {{ getCoordinates(item.latitude, item.longitude) }}
                  </small>
                </template>
                <br />
                <span
                  class="primary--text installationMessage"
                  v-if="showDisabledMessage[item.id]"
                >
                  One or more sensors are disabled
                </span>
              </span>
            </v-flex>
          </v-layout>

          <!-- Add telemetry if the installation is of Tethered drone type -->
          <v-flex
            v-if="(item.sentry_type === 'drone' && !editMode) || demo_mode"
            xs12
          >
            <v-subheader>
              Telemetry
            </v-subheader>

            <TelemetryPanel :sentry="activeSentryInformation" />

            <v-subheader>
              Flight Controls
            </v-subheader>
            <DroneFlightControl
              :activeSentry="activeSentryInformation"
              @cameraToggled="cameraToggle"
              :activeCameraId="activeCameraId"
            />
          </v-flex>

          <sentry
            :sentry="item"
            :cameraPage="cameraPage"
            :edit-mode="editMode"
          ></sentry>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import Sentry from '@/components/Sentry/SentryCard'
import flatten from 'lodash/flatten'
import { SentryMixin, SensorMixin } from '@/components/Mixins'
import TelemetryPanel from './Telemetry/TelemetryPanel.vue'
import DroneFlightControl from './FlightControl/Components/DroneFlightControl.vue'
import { latLngToMgrs } from '@/utils/utils.js'

const props = {
  title: {
    type: String,
    default: 'Installations'
  },
  editMode: {
    type: Boolean,
    default: false
  },
  cameraPage: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'InstallationsWidget',
  components: {
    Sentry,
    TelemetryPanel,
    DroneFlightControl
  },
  mixins: [SentryMixin, SensorMixin],
  props,
  mounted() {
    if (this.cameraPage) {
      for (let el of [
        ...document.querySelectorAll(
          '.v-card__text > ul > li > .v-expansion-panel__header'
        ),
        ...document.querySelectorAll('.v-list__group__header')
      ])
        el.style = 'pointer-events: none; padding-bottom:0px;'
    }
  },
  data: () => ({
    isDroneSentryType: null
  }),
  computed: {
    ...mapGetters('users', ['isAuthorized']),
    ...mapState('selection', [
      'activeSentries',
      'activeGroups',
      'activeSensors',
      'activeCamera'
    ]),
    ...mapState('drone_mcu_units', ['demo_mode']),
    ...mapState('sites', ['activeSiteId', 'activeSite']),
    ...mapState('sentries', ['activeSentryId', 'activeSentry']),
    ...mapState(['mgrsEnabled']),
    ...mapState('site_warnings', ['site_warnings', 'site_warnings_history']),
    ...mapState(['system', 'environment']),
    ...mapGetters('sentries', ['sentriesInSite']),
    ...mapGetters('sensors', [
      'allSensorsInSentry',
      'radarsInSentry',
    ]),
    ...mapGetters('cameras', ['allCameras', 'allCamerasInSentry']),
    ...mapGetters('rf_sensors', ['allRfSensorsInSentry', 'allDsxSensorsInSentry']),
    sensorColors() {
      const values = {}
      this.sentries.forEach(sentry => {
        const sensors = this.allSensorsInSentry(sentry)
        sensors.cameras = this.allCamerasInSentry(sentry.id)
        sensors.rf_sensors = this.allRfSensorsInSentry(sentry.id)
        sensors.dsx_sensors = this.allDsxSensorsInSentry(sentry.id)

        let colorList = []
        for (const [sensorType, sensorList] of Object.entries(sensors)) {
          colorList.push(
            sensorList.map(sensor => {
              return this.$_sensorStatus(sensor, sensorType)
            })
          )
        }
        values[sentry.id] = flatten(colorList)
      })
      return values
    },
    sentryColors() {
      const values = {}
      this.sentries.forEach(sentry => {
        values[sentry.id] = this.$_getStatusColor(sentry)
      })
      return values
    },
    showDisabledMessage() {
      const values = {}
      this.sentries.forEach(sentry => {
        const id = sentry.id
        values[id] =
          this.sentryColors[id] === 'green' &&
          this.sensorColors[id].includes('NIL')
      })
      return values
    },
    hasPinnedCards() {
      const sessionStorageKeys = Object.keys(sessionStorage)
      return this.sentries.map(sentry => {
        return sessionStorageKeys.includes(
          `pinnedCards_${sentry.site_id}_${sentry.id}`
        )
      })
    },
    panelStates: {
      get() {
        return this.sentries.map(sentry => {
          return this.activeSentries.includes(sentry.id)
        })
      },
      set() { }
    },
    sentries() {
      return this.sentriesInSite(this.activeSiteId)
    },

    openPanel() {
      return this.cameraPage
        ? this.sentries.map(v => !!v)
        : this.sentries.findIndex(s => s.id === this.activeSentryId)
    },
    activeSentryInformation() {
      return !this.demo_mode && this.environment === 'ZenithAeroTech'
        ? this.sentries.find(sentry => sentry.id === this.activeSentryId)
        : this.sentries.find(sentry => sentry.id === this.activeSentryId || 1)
    },
    activeCameraId() {
      return this.activeCamera?.id
    }
  },
  methods: {
    ...mapActions('sentries', { selectSentry: 'SELECT_SENTRY' }),
    ...mapActions('selection', [
      'clearSentries',
      'activateSentry',
      'deactivateSentry',
      'deactivateSensor',
      'deactivateGroup',
      'clearSensors',
      'clearGroups',
      'setActiveCamera'
    ]),
    addNewInstallation() {
      this.$router.push({
        name: 'installations.edit',
        params: {
          id: 'new',
          sentry: null
        }
      })
    },
    panelUpdate(val) {
      val.forEach((state, index) => {
        const sentry = this.sentries[index]
        if (state) {
          this.activateSentry(sentry.id)
          if (this.editMode) this.selectSentry(sentry.id)
        } else {
          if (this.editMode) this.$store.dispatch('sensors/UNSELECT_ALL')

          this.deactivateSentry(sentry.id)
        }
      })
    },
    //returns mgrs or lat/long based on settings
    getCoordinates(lat, long) {
      if (this.mgrsEnabled) return latLngToMgrs(lat, long, true)
      return `${lat.toFixed(5)} ${long.toFixed(5)}`
    },
    updateLatLng(e) {
      this.$bus.$emit('updateLatLng', [this.activeSentryId, e])
    },
    cameraToggle() {
      if (!this.activeCameraId) {
        console.warn('You need to connect a camera to see the feed')
      }
      if (this.activeCameraId) {
        this.setActiveCamera(null)
      } else {
        //find the first camera for this sentry/installation and activateit
        const matchingCamera = this.allCameras.find(
          c => c.sentry_id === this.activeSentryId
        )
        this.setActiveCamera(matchingCamera)
      }
    }
  },
  watch: {
    hasPinnedCards: {
      handler: function(val) {
        Object.keys(val).forEach(key => {
          if (val[key]) {
            this.activateSentry(this.sentries[key].id)
          }
        })
      },
      immediate: true
    },
    activeSentryId(id) {
      if (!id) {
        this.$store.commit('setActiveGroup', {})
      }
      this?.activeSentryInformation?.sentry_type === 'drone'
        ? (this.isDroneSentryType = true)
        : (this.isDroneSentryType = false)
    }
  }
}
</script>

<style>
.installationMessage {
  font-size: 0.7em;
}
</style>
